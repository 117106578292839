import React from 'react';
import Lodash from 'lodash';
import { connect, MapStateToProps } from 'react-redux';
import { Selector } from 'reselect';
import { getAllChannelsGroupedByIds } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import {
  getSeoData as getBaseSeoData,
  ChannelSeoData as BaseChannelSeoData,
  VideoSeoData as BaseVideoSeoData,
  GetSeoDataParams,
} from '../../worker/helpers/getSeoData';
import { getVideosGroupedByIds } from '../../selectors/videos';
import { withWidgetProps } from '../../containers/widget-props';
import {
  PublicChannelItem,
  PublicVideoItem,
} from '@wix/wix-vod-api/dist/src/public';
import { ControllerProps } from '../../index';
import { RootState } from '../../redux/root-reducer';

interface ChannelSeoData extends BaseChannelSeoData {
  '@context': 'http://schema.org';
  '@type': 'MediaObject';
}
interface VideoSeoData extends BaseVideoSeoData {
  '@context': 'http://schema.org';
  '@type': 'VideoObject';
}

interface SeoData extends ChannelSeoData {
  videos: VideoSeoData[];
}

function getSeoData({ channel, videos }: GetSeoDataParams): SeoData {
  const baseSeoData = getBaseSeoData({ channel, videos });
  return {
    '@context': 'http://schema.org',
    '@type': 'MediaObject',
    ...baseSeoData,
    videos: baseSeoData.videos.map((video) => ({
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      ...video,
    })),
  };
}

interface WidgetProps {
  captureException: (error: Error) => void;
}
interface OwnProps {}
interface DispatchProps {}
interface StateProps {
  channels: PublicChannelItem[];
  videos: PublicVideoItem[];
}

type Props = OwnProps & StateProps & DispatchProps & WidgetProps;

class SeoComponent extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { channels, videos } = this.props;

    let serializedSeoData = null;

    try {
      const seoData = getSeoData({
        channel: channels[0],
        videos,
      });
      serializedSeoData = seoData && JSON.stringify(seoData);
    } catch (err) {
      this.props.captureException(err as Error);
    }

    if (!serializedSeoData) {
      return null;
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: serializedSeoData }}
      />
    );
  }
}

const selector = ({ captureException }: ControllerProps) => ({
  captureException,
});

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state,
) => ({
  channels: Lodash.values(getAllChannelsGroupedByIds(state)),
  videos: Lodash.values(
    (getVideosGroupedByIds as unknown as Selector)(state),
  ).filter((video) => video.id),
});
export default withWidgetProps(selector)(
  connect<StateProps, void, OwnProps, RootState>(mapStateToProps)(SeoComponent),
);
