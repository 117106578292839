import React from 'react';
import { ControllerProps } from '../index';

const WidgetPropsContext = React.createContext<ControllerProps | undefined>(
  undefined,
);

export const WidgetPropsProvider = WidgetPropsContext.Provider;

export function withWidgetProps(
  selector: (props: ControllerProps) => { [key: string]: any },
) {
  return (Component: React.JSXElementConstructor<any>) => (props: any) =>
    (
      <WidgetPropsContext.Consumer>
        {(widgetProps) =>
          widgetProps ? (
            <Component {...props} {...selector(widgetProps)} />
          ) : null
        }
      </WidgetPropsContext.Consumer>
    );
}
