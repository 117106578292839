import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  backgroundColor: StyleParamType.Color;
  textColor: StyleParamType.Color;
  buttonsAndLinksColor: StyleParamType.Color;
  mainButtonColor: StyleParamType.Color;
  mainButtonTextColor: StyleParamType.Color;
  mobileMenuTextColor: StyleParamType.Color;
  mobileMenuBackgroundColor: StyleParamType.Color;
  thumbnailButtonsColor: StyleParamType.Color;
  mobileButtonsTextColor: StyleParamType.Color;
  mobileButtonsBackgroundColor: StyleParamType.Color;
  dividersAndArrowsColor: StyleParamType.Color;
  emptyPlayIconEditor: StyleParamType.Color;
  emptyPlayIconSite: StyleParamType.Color;
  emptyPlayerBgEditor: StyleParamType.Color;
  emptyPlayerBgSite: StyleParamType.Color;
  compactMenuBackgroundColor: StyleParamType.Color;
  compactMenuActionsColor: StyleParamType.Color;
  thumbnailOverlayColor: StyleParamType.Color;

  textFont: StyleParamType.Font;
  titleFont: StyleParamType.Font;
  videoInfoFont: StyleParamType.Font;
};

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonsAndLinksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileMenuTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileMenuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thumbnailButtonsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileButtonsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileButtonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividersAndArrowsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  emptyPlayIconEditor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  emptyPlayIconSite: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  emptyPlayerBgEditor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  emptyPlayerBgSite: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  compactMenuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  compactMenuActionsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  thumbnailOverlayColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Title'),
  },
  videoInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S'),
  },
});
