import React, { FC, useCallback, useEffect, useState } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../stylesParams';
import { StyleParamType } from '@wix/tpa-settings';
import { IWixStyleColor, IWixStyleFont } from '@wix/yoshi-flow-editor';

type Style = Record<string, string>;

const formatValue = (
  type: StyleParamType,
  setting: IWixStyleFont | IWixStyleColor,
) => {
  if (type === StyleParamType.Font) {
    const value = setting.value;
    if (value) {
      return value?.replace(/^font:/, '').replace(/;$/, '');
    }

    const font = setting as IWixStyleFont;
    const values = [];

    values.push(font.style?.bold ? 'bold' : 'normal');
    values.push(font.style?.italic ? 'italic' : 'normal');
    values.push(
      typeof font.size === 'number' ? `${font.size}px/${font.size}px` : '1em',
    );
    values.push(font.family);

    return values.join(' ');
  }

  if (type === StyleParamType.Color) {
    // need only numbers to use it in rgba function
    return setting.value?.replace(/rgba?\((.*)\)/, '$1');
  }

  return setting.value;
};

const useStyleVariables = () => {
  const styles = useStyles();

  const updateStyleParams = useCallback(() => {
    if (styles.ready) {
      const newStyle: Style = {};

      Object.values(stylesParams).forEach((param) => {
        const setting = styles.get(param);
        newStyle[`--${param.key}`] = formatValue(param.type, setting);
      });

      return newStyle;
    }
  }, [styles]);

  const [styleValue, setStyleValue] = useState<Style>(
    updateStyleParams() || {},
  );

  useEffect(() => {
    const newStyle = updateStyleParams();
    if (newStyle) {
      setStyleValue(newStyle);
    }
  }, [updateStyleParams]);

  return styleValue;
};

export const StyleParamsCSSVariables: FC = ({ children }) => {
  const style = useStyleVariables();

  return (
    <div style={style} data-hook="style-params-css-variables">
      {style ? children : null}
    </div>
  );
};
